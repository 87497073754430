// jshint ignore: start
import React, { useState } from 'react';
import Layout from "../components/layout"
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import SEO from "../components/seo"
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'gatsby-plugin-intl';

const useStyles = makeStyles(theme => ({
    formroot: {
        width: 170,
        marginTop: 10,
        marginBottom: 20
    },
}));

function VerifyPageContent() {
    const classes = useStyles();
    const intl = useIntl();
   

    return (
        <React.Fragment>
            <SEO title={intl.formatMessage({id: "ver.verify"})} />
            <h1>{intl.formatMessage({id: "ver.thankyou"})}!</h1>
            <Divider style={{marginTop: 20}}/>
            <div style={{ padding: "20px 0 20px 0" }}>
                <Typography style={{marginBottom: 24}} variant="body1" component="p">
                    {intl.formatMessage({id: "ver.you"})}
                </Typography>
                <Typography style={{marginBottom: 24}} variant="body1" component="p">
                    {intl.formatMessage({id: "ver.toconfirm"})}
                </Typography>
                <Typography style={{marginBottom: 24}} variant="body1" component="p">
                    {intl.formatMessage({id: "ver.ifyou"})}{' '}<a style={{textDecoration: 'none'}} href="mailto:support@acroleads.com">support@acroleads.com</a>
                </Typography>
                <Typography style={{marginBottom: 24}} variant="body1" component="p">
                    {intl.formatMessage({id: "ver.using"})}
                </Typography>
                <Divider style={{marginTop: 20}}/>
            </div>
        </React.Fragment>
    )
}

const VerifyPage = () => (
  <Layout>
    <div>
        <VerifyPageContent/>
    </div>
  </Layout>
)

export default VerifyPage
